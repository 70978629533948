import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import BootstrapVue3 from "bootstrap-vue-3";
import VueApexCharts from "vue3-apexcharts";
import vClickOutside from "click-outside-vue3";
import { registerScrollSpy } from "vue3-scroll-spy";
import jQuery from "jquery";

import Maska from "maska";
import { i18n } from "./i18n.js";
import { initFirebaseBackend } from "./authUtils";
import { configureFakeBackend } from "./helpers/fake-backend";
import popups from "./services/popups";
import http from "./services/http";
import $e from "./services/$e";
import PrimeVue from "primevue/config";
import AutoComplete from "primevue/autocomplete";
import login from "./services/Login";
// import scrollMixin from "./services/scrollMixin";


const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID,
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else {
  configureFakeBackend();
}

import "sweetalert2/dist/sweetalert2.min.css";
import "@vueform/slider/themes/default.css";
import store from "./state/store";
import "../src/design/app.scss";






// const url = "http://localhost:8080"
// // let mixins = {};
// const loadLinks = async (type, url) => {
//   if (type == "css") {
//     console.log(url + "with type" + type, "loaded successfully")
//     const iconURL = url;
//     const link = document.createElement('link');
//     link.rel = 'stylesheet';
//     link.href = iconURL;
//     document.head.appendChild(link);
//   } else {
//     console.log(url + "with type" + type, "loaded successfully")
//     const iconURL = url
//     const script = document.createElement('script');
//     script.src = iconURL;
//     document.head.appendChild(script);

//   }
// }
// async function hendlLinks() {
//   await loadLinks("css", url + "/website/css/style-starter.css")
//   await loadLinks("js", url + "/website/js/jquery-3.3.1.min.js")
//   // await loadLinks("","https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.1/jquery.min.js")
//   await loadLinks("js", url + "/website/js/theme-change.js")
//   await loadLinks("js", url + "/website/js/bootstrap.min.js")
// }

// if (!window.location.href.includes(url + "/dashboard")) {
//   // mixins = scrollMixin
//   hendlLinks();
// }


var app = createApp(App)
  .use(store)
  .use(router)
  .use(require("vue-chartist"))
  .use(BootstrapVue3)
  .use(VueApexCharts)
  .use(vClickOutside)
  .use(i18n)
  .use(registerScrollSpy)
  .use(Maska)
  .use(popups)
  .use(http)
  .use($e)
  .use(PrimeVue)
  .use(jQuery)
  .use(login)
  // .use(url)
  // .use(mixins);

app.component("AutoComplete", AutoComplete);
app.mount("#app");
