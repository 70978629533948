// import store from "@/state/store";

export default [

  {
    path: '/branches',
    name: 'branches',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Branches/BranchesPage"),
  },


  {
    path: '/visa-tickets',
    name: 'visa_tickets',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/VisaTickets/VisaTicketsPage.vue"),
  },
  {
    path: '/returned',
    name: 'returned',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/VisaTickets/returnedPage.vue"),
  },

  {
    path: '/insurance',
    name: 'insurance',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/VisaTickets/insurancePage.vue"),
  },


  {
    path: '/countries',
    name: 'countries',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Countries/CountriesPage"),
  },



  {
    path: '/login',
    name: 'login',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Login/LoginPage"),
  },


  {
    path: '/customer-files/:id',
    name: 'customer-files',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/CustomerFiles/CustomerFilesPage"),
  },


  {
    path: '/customers',
    name: 'customers',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Customers/CustomersPage"),
  },


  {
    path: '/orders',
    name: 'orders',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Orders/OrdersPage"),
  },


  {
    path: '/users',
    name: 'users',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Users/UsersPage"),
  },


  {
    path: '/workers',
    name: 'workers',
    meta: {
      authRequired: true,
    },
    component: () => import("./views/Workers/WorkersPage"),
  },


]


